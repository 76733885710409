import React, { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { PaystackButton } from "react-paystack";
import { Navigate, useNavigate } from "react-router-dom";

const Payment = ({ runSaveHandleItemPurchased, finalPrice }) => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const paystackConfig = {
    reference: new Date().getTime().toString(),
    email: currentUser.email,
    amount: finalPrice * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_live_0eee35e3d978304cfcd86fb64f49d19487491a8f",
  };

  const handlePaystackSuccessAction = async () => {
    // console.log("SUCCESSFULLL!!", reference);
    await runSaveHandleItemPurchased();
  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("paystack closed");
  };

  const componentProps = {
    ...paystackConfig,
    text: "Confirm Purchase",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  return <PaystackButton {...componentProps} className="btn btn-primary m-1" />;
};

export default Payment;
