import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PaymentButton from "../../components/payment/Payment";
import { AuthContext } from "../../context/authContext";
import "./coinpuchse.scss";
import { makeRequest } from "../../axios";
import Loading from "../../components/loading/Loading";

const CoinPurchase = () => {
  const state = useLocation().state;

  const [count, setCount] = useState(state?.coins || 0);
  const [count2, setCount2] = useState(count);
  const [rec, setRec] = useState("");
  const [err, setErr] = useState(null);
  const [isLoading, setLoad] = useState(false);

  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const id = currentUser?.id;
  const email = currentUser?.email;
  const send = currentUser?.storename;

  useEffect(() => {
    if (count < 1) {
      setCount(1);
    }
    if (count > 9) {
      setCount2(parseInt(count) + 1);
    } else {
      setCount2(count);
    }
    console.log("coins: " + count);
    console.log("charge: " + count2);
  }, [count]);

  // const increment = () => {
  //   setCount(count + 1);
  // };

  // const decrement = () => {
  //   setCount(count - 1);
  // };

  const handleShare = async (e) => {
    try {
      setLoad(true);
      await makeRequest.post(`/users/sharecoins`, {
        coins: count,
        id: id,
        rec: rec,
        charge: count2,
        email: email,
        send: send,
      });
      navigate(`/profile/${currentUser.storename}`, {
        state: { fromm: "Coin shared successfully" },
      });
    } catch (err) {
      const data = err.response?.data;
      if (data) {
        setErr(data);
        setLoad(false);
        console.log(err);
      } else {
        setErr("something went wrong");
        setLoad(false);
        console.log(err);
      }
      console.log(err);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <p>
        <h3 className="text-center mt-3">Share Coins</h3>
      </p>
      {err && <p className="text-danger text-center text-capitalize">{err}</p>}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className="counter-container">
          <p className="coin-display">Coins to be shared:{count}</p>
          <p className="coin-display">Coins to be charged:{count2}</p>

          <input
            placeholder="Set amount of coins (e.g., 50)"
            type="number"
            value={state && count}
            className="form-control text-center"
            inputMode="numeric"
            onChange={(e) => setCount(e.target.value)}
          />
          <input
            placeholder="Receiver's unique storename"
            type="text"
            value={rec}
            className="form-control text-center"
            onChange={(e) => setRec(e.target.value)}
          />

          <p className="text-center">
            <button onClick={handleShare} className="btn btn-primary">
              Share
            </button>{" "}
          </p>
        </div>
      )}
    </div>
  );
};

export default CoinPurchase;
