import React from "react";

const CatCar = () => {
  return (
    // <div
    //   id="carouselExampleAutoplaying"
    //   class="carousel slide"
    //   data-bs-ride="carousel"
    //   style={{ backgroundColor: "#344239", width: "100%" }}
    // >
    //   <div className="carousel-inner">
    //     <a className={"carousel-item "} href={"/cat/ClothingM"}>
    //       <img
    //         // style="object-fit: contain; max-height: 400px"
    //         style={{ objectFit: "contain", maxHeight: "400px" }}
    //         src={"../../img/s3.jpg"}
    //         class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
    //         xmlns="http://www.w3.org/2000/svg"
    //         role="img"
    //         aria-label="Placeholder: Third slide"
    //         preserveAspectRatio="xMidYMid slice"
    //         focusable="false"
    //       />
    //     </a>
    //   </div>

    //   <div className="carousel-inner active">
    //     <a className={"carousel-item "} href={"/cat/accessoriesM"}>
    //       <img
    //         // style="object-fit: contain; max-height: 400px"
    //         style={{ objectFit: "contain" }}
    //         src={"../../img/s1.jpg"}
    //         class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
    //         xmlns="http://www.w3.org/2000/svg"
    //         role="img"
    //         aria-label="Placeholder: Third slide"
    //         preserveAspectRatio="xMidYMid slice"
    //         focusable="false"
    //       />
    //     </a>
    //   </div>

    //   <div className="carousel-inner">
    //     <a className={"carousel-item "} href={"/cat/accessoriesW"}>
    //       <img
    //         // style="object-fit: contain; max-height: 400px"
    //         style={{ objectFit: "contain" }}
    //         src={"../../img/s2.jpg"}
    //         class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
    //         xmlns="http://www.w3.org/2000/svg"
    //         role="img"
    //         aria-label="Placeholder: Third slide"
    //         preserveAspectRatio="xMidYMid slice"
    //         focusable="false"
    //       />
    //     </a>
    //   </div>

    //   <div className="carousel-inner">
    //     <a className={"carousel-item "} href={"/cat/ClothingW"}>
    //       <img
    //         // style="object-fit: contain; max-height: 400px"
    //         style={{ objectFit: "contain" }}
    //         src={"../../img/s4.jpg"}
    //         class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
    //         xmlns="http://www.w3.org/2000/svg"
    //         role="img"
    //         aria-label="Placeholder: Third slide"
    //         preserveAspectRatio="xMidYMid slice"
    //         focusable="false"
    //       />
    //     </a>
    //   </div>
    //   <button
    //     class="carousel-control-prev"
    //     type="button"
    //     data-bs-target="#carouselExampleAutoplaying"
    //     data-bs-slide="prev"
    //     fdprocessedid="1qnhq"
    //   >
    //     <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    //     <span class="visually-hidden">Previous</span>
    //   </button>
    //   <button
    //     class="carousel-control-next"
    //     type="button"
    //     data-bs-target="#carouselExampleAutoplaying"
    //     data-bs-slide="next"
    //     fdprocessedid="rlmy7u"
    //   >
    //     <span class="carousel-control-next-icon" aria-hidden="true"></span>
    //     <span class="visually-hidden">Next</span>
    //   </button>
    // </div>
    <div
      id="carouselExampleAutoplaying"
      class="carousel slide"
      data-bs-ride="carousel"
      //   style="background-color: #344239"
      style={{ backgroundColor: "#344239", width: "100%" }}
    >
      <div class="carousel-inner">
        <a class="carousel-item" href="/cat/ClothingW">
          <img
            // style="object-fit: contain; max-height: 400px"
            style={{ objectFit: "contain", maxHeight: "400px" }}
            src="../../img/s4.jpg"
            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Placeholder: Third slide"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          />
        </a>
        <a class="carousel-item active" href="/cat/accessoriesM">
          <img
            // style="object-fit: contain; max-height: 400px"
            style={{ objectFit: "contain", maxHeight: "400px" }}
            src="../../img/s1.jpg"
            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Placeholder: Third slide"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          />
        </a>
        <a class="carousel-item" href="/cat/accessoriesW">
          <img
            // style="object-fit: contain; max-height: 400px"
            style={{ objectFit: "contain", maxHeight: "400px" }}
            src="../../img/s2.jpg"
            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Placeholder: Third slide"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          />
        </a>
        <a class="carousel-item" href="/cat/ClothingM">
          <img
            // style="object-fit: contain; max-height: 400px"
            style={{ objectFit: "contain", maxHeight: "400px" }}
            src="../../img/s3.jpg"
            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Placeholder: Third slide"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          />
        </a>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
        fdprocessedid="1qnhq"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
        fdprocessedid="rlmy7u"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
      <p className="text-center">
        <b style={{ color: "#EAEAE2" }}>Top categories</b>
      </p>
    </div>
  );
};

export default CatCar;
