import React, { useContext, useEffect, useState, useCallback } from "react";
import { makeRequest } from "../../axios";
import Loading from "../loading/Loading";
import NoItems from "../NoItems/NoItems";
import Post from "../post/Post";
import "./posts.scss";
import { useInfiniteQuery } from "@tanstack/react-query";
import { AuthContext } from "../../context/authContext";
import { useInView } from "react-intersection-observer";
import Load from "../Load";
import ShareButton from "../share/Share";

const Posts = ({ userId, googlesearch, name }) => {
  const { currentUser } = useContext(AuthContext);
  const [text, setText] = useState(null);

  useEffect(() => {
    if (currentUser && currentUser.storename === userId) {
      setText("Your Store has no products");
    } else {
      setText("This Store has no products");
    }
  }, [currentUser, userId]);

  const [search, setSearch] = useState(
    decodeURIComponent(decodeURIComponent(googlesearch || ""))
  );
  const keys = ["storename", "title", "location", "storetitle", "cat", "desc"];
  const encodedSearch = encodeURIComponent(encodeURIComponent(search));

  const content = {
    title: "Salfad Marketplace",
    text: `Check out amazing products from ${name} on salfad.com`,
    url: `https://www.salfad.com/profile/${userId}/?${encodedSearch}`,
  };

  const fetchUserPosts = ({ pageParam = 0 }) =>
    makeRequest
      .get(`/posts/user/${userId}?limit=5&offset=${pageParam}`)
      .then((res) => res.data);

  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["UserPost", userId],
    queryFn: fetchUserPosts,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length > 0 ? pages.length * 5 : undefined;
    },
  });

  const allPosts = data ? data.pages.flat() : [];

  const { ref, inView } = useInView();

  const loadMorePosts = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (inView) {
      loadMorePosts();
    }
  }, [inView, loadMorePosts]);

  return (
    <div className="posts">
      <p
        className="row sticky-top top-0 p-2 shadow-sm "
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "white",
        }}
      >
        <input
          className="form-control m-2 col-md-3"
          type="search"
          placeholder="Search this store"
          aria-label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search.length > 0 && (
          <ShareButton
            title={content.title}
            text={content.text}
            url={content.url}
          />
        )}
      </p>
      {isError ? (
        "Something went wrong"
      ) : isLoading && !allPosts.length ? (
        <Loading />
      ) : !allPosts.length ? (
        text === "Your Store has no products" ? (
          <NoItems text={text} />
        ) : (
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <p className="text-capitalize" style={{ width: "70vw" }}>
              {text}
            </p>
            <a href="/home/" className="btn btn-primary">
              Go Back
            </a>
          </div>
        )
      ) : (
        <>
          {allPosts
            .filter((post) =>
              keys.some((key) =>
                post[key]?.toLowerCase().includes(search.toLowerCase())
              )
            )
            .map((post) => (
              <Post post={post} key={post.id} />
            ))}
          <div
            ref={ref}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "100%",
            }}
          >
            {isFetchingNextPage && (
              <div
                style={{
                  position: "fixed",
                  bottom: "0px",
                  marginBottom: "61px",
                }}
              >
                <Load />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Posts;
