// ShareButton.js
import React from "react";

const ShareButton = ({ title, text, url }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({ title, text, url });
        alert("Search shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback: Copy URL to clipboard
      navigator.clipboard.writeText(url).then(() => {
        alert("URL copied to clipboard!");
      });
    }
  };

  return (
    <button onClick={handleShare} className="btn btn-primary btn-sm col-4">
      Share search
    </button>
  );
};

export default ShareButton;
