import React from "react";
import { RotatingLines } from "react-loader-spinner";

import "./LoadingAnimation.css";
import Load from "../Load";

const Loading = () => {
  return (
    <div>
      {" "}
      <div className="loading-container ">
        <Load />
      </div>
    </div>
  );
};

export default Loading;
