import React from "react";
import "./land.css";

const Landing = () => {
  return (
    <div className="landing">
      <div className="jumbotron text-center text-capitalize">
        <h1 className="display-4">Welcome to Salfad marketplace!</h1>
        <p className="lead">Buy, Sell, Connect</p>
        <hr className="my-4" />
        <p className="p-1">
          Explore exceptional goods, showcase your products, and connect with
          vendors and enthusiasts
        </p>
        <a
          className="btn btn-primary m-1 text-capitalize"
          href="/register"
          role="button"
        >
          Register your store
        </a>
        <a
          className="btn btn-success m-1 text-capitalize"
          href="home"
          role="button"
        >
          explore products
        </a>
      </div>
      <div className="container p-4 mb-5">
        <div className="row text-center mb-5">
          <div className="col-md-4">
            <h2 className="text-secondary">Buy</h2>
            <p>
              Discover exceptional items from diverse categories and sellers.
            </p>
          </div>
          <div className="col-md-4">
            <h2 className="text-secondary">Sell</h2>
            <p>Showcase your products to a broad audience</p>
          </div>
          <div className="col-md-4">
            <h2 className="text-secondary">Connect</h2>
            <p>
              Build business relationships and grow your network with
              like-minded people
            </p>
          </div>
        </div>
      </div>
      <div className="footer text-center position-fixed bottom-0 mt-2 mb-5">
        <p>
          By continuing, you have accepted our{" "}
          <a href="/terms">Terms of Service</a> and{" "}
          <a href="/privacy">Privacy Policy</a>.
        </p>
      </div>
    </div>
  );
};

export default Landing;
