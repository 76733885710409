import React from "react";

const CatList = () => {
  return (
    <div>
      <p
        className="text-center"
        style={{ fontWeight: "bold", textDecoration: "underline" }}
      >
        Choose Category
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          marginTop: "100px",
        }}
      >
        {[
          { value: "ClothingM" },
          { value: "ClothingW" },
          { value: "accessoriesM" },
          { value: "accessoriesW" },
          { value: "Perfumes" },
          { value: "Beauty" },
          { value: "mobile" },
          { value: "vehicles" },
          { value: "furniture" },
          { value: "kitchen" },
          { value: "books" },
        ].map((item, index) => (
          <div key={index}>
            {" "}
            <a className="navbar-brand" href={`/cat/${item.value}`}>
              <img
                src={`../../img/${item.value}.jpg`}
                style={{
                  minWidth: "100px",
                  minHeight: "100px",

                  objectFit: "cover",
                }}
                alt=""
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatList;
