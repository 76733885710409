import React from "react";

const Terms = () => {
  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h2 className="text-center mb-4">
            <b>Terms of Service</b>
          </h2>
          <p>
            Welcome to Salfad Marketplace! By using our services, you agree to
            the following terms:
          </p>
          <p>
            <b>1. Service Description:</b>
            <ul>
              <li>
                Salfad Marketplace connects buyers with sellers of various
                products.
              </li>
              <li>
                We provide buyers with the sellers' WhatsApp contact information
                so they can communicate directly.
              </li>
            </ul>
          </p>
          <p>
            <b>2. Disclaimer:</b>
            <ul>
              <li>
                Salfad Marketplace is solely a platform for facilitating
                connections between buyers and sellers.
              </li>
              <li>
                We do not guarantee the quality, safety, or legality of the
                items listed, the truth or accuracy of the listings, the ability
                of sellers to sell items, or the ability of buyers to pay for
                items.
              </li>
            </ul>
          </p>
          <p>
            <b>3. Disputes and Fraud:</b>
            <ul>
              <li>
                Any disputes, disagreements, or misunderstandings arising from
                transactions between buyers and sellers, including but not
                limited to issues of product quality, incorrect items delivered,
                or any fraudulent activities, are strictly between the buyer and
                the seller.
              </li>
              <li>
                Salfad Marketplace is not responsible for resolving any disputes
                and will not be liable for any damages or losses incurred during
                transactions.
              </li>
            </ul>
          </p>
          <p>
            <b>4. Liability:</b>
            <ul>
              <li>
                By using Salfad Marketplace, you acknowledge and agree that we
                are not responsible or liable for any loss or damage of any kind
                incurred as a result of any transaction.
              </li>
              <li>
                This includes, but is not limited to, any indirect, incidental,
                special, consequential, or punitive damages arising out of or
                related to your use of our services.
              </li>
            </ul>
          </p>
          <p>
            <b>5. Vendors & Coins:</b>
            <ul>
              <li>
                Vendors on Salfad Marketplace have the option to purchase coins
                that can be used to carry out various functions within our
                platform.
              </li>
              <li>
                Coins are non-refundable and cannot be exchanged for money once
                purchased.
              </li>
              <li>
                It is the vendor's responsibility to manage their coins
                accordingly.
              </li>
            </ul>
          </p>
          <p>
            <b>6. Acceptance:</b>
            <ul>
              <li>
                By continuing to use Salfad Marketplace, you accept and agree to
                these terms of use and our privacy policy.
              </li>
            </ul>
          </p>
          <p className="text-center">
            Thank you for choosing Salfad Marketplace!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
