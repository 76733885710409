import React from "react";

const Err404 = () => {
  return (
    <div className="position-absolute top-50 start-50 translate-middle">
      <div className="text-center text-uppercase">
        <h1>
          <b>
            ERROR 404; <br />
          </b>
        </h1>
        PAGE NOT FOUND. It seems you're lost <br />
        <a href="/home/" className="btn btn-primary">
          Go Home
        </a>
      </div>{" "}
    </div>
  );
};

export default Err404;
