import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { makeRequest } from "../../axios";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../context/authContext";
import Loading from "../../components/loading/Loading";
import NoItems from "../../components/NoItems/NoItems";

const Single = () => {
  const { currentUser } = useContext(AuthContext);
  const state = useLocation().state;
  const navigate = useNavigate();

  const postId = useLocation().pathname.split("/")[2];
  const delCoin = 2;
  const ediCoin = 3;
  // const proCoin = 15;

  // Handle the case where currentUser might be null

  const [uid, setId] = useState(currentUser?.id);
  const [isLoading, setLoad] = useState(false);
  const [lowCoin, setlowCoin] = useState(false);
  const [needCoins, setneedCoins] = useState(0);

  const { isPending, error, data } = useQuery({
    queryKey: ["Singlepost"],
    queryFn: () =>
      makeRequest.get("/posts/" + postId).then((res) => {
        return res.data;
      }),
  });

  const handleNoCoins = (funcoin, availcoin) => {
    setlowCoin(true);

    const coin = availcoin - funcoin;
    setneedCoins(coin * -1);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (!uid || !currentUser) {
      console.error("currentUser or uid is null");
      return;
    }

    try {
      setLoad(true);

      await makeRequest.delete(`/posts/${postId}`, {
        data: { uid, coins: delCoin, id: currentUser.id },
      });
      navigate(`/profile/${currentUser.storename}`, {
        state: { fromm: "Product has been Deleted" },
      });
    } catch (error) {
      console.error("Error deleting post", error);
    }
  };

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");

  const openLightbox = (image) => {
    setLightboxImage(image);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxImage("");
  };

  return (
    <div className="posts">
      {error ? (
        "Something went wrong"
      ) : isPending ? (
        <Loading />
      ) : data && data.length < 1 ? (
        <NoItems text={"Product Does Not exist"} />
      ) : (
        data.map((post) => {
          const formattedPrice = new Intl.NumberFormat("en-NG", {
            style: "currency",
            currency: "NGN",
          }).format(post.price);

          return (
            <div post={post} key={post.id}>
              {state && (
                <div
                  className="alert alert-dark alert-dismissible fade show text-capitalize text-center fixed-bottom mb-5"
                  role="alert"
                >
                  {state.fromm}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              )}
              {lowCoin && (
                <div
                  className="alert alert-danger alert-dismissible fade show text-capitalize text-center fixed-bottom mb-5"
                  role="alert"
                >
                  <p>
                    <h1>Not Enough Coins</h1>
                    <p>
                      You need {needCoins} more coins to carry out this command
                    </p>
                    <br />
                    <Link
                      className="btn btn-primary text-capitalize"
                      to={"/buycoins"}
                      state={{ coins: needCoins }}
                    >
                      Purchase More
                    </Link>
                  </p>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setlowCoin(false)}
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              )}
              {isLoading ? (
                <Loading />
              ) : (
                <div className="container">
                  <div className="card" style={{ width: "80vw" }}>
                    <div className="card-body">
                      <div className="text-center">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            overflow: "scroll",
                          }}
                        >
                          {post.mainimg && (
                            <img
                              src={post.mainimg}
                              style={{
                                maxWidth: "400px",
                                height: "300px",
                                cursor: "pointer",
                                objectFit: "scale-down",
                              }}
                              className="card-img-top m-1"
                              alt="..."
                              onClick={() => openLightbox(post.mainimg)}
                            />
                          )}
                          {post.subimg1 && (
                            <img
                              src={post.subimg1}
                              style={{
                                maxWidth: "400px",
                                height: "300px",
                                cursor: "pointer",
                                objectFit: "scale-down",
                              }}
                              className="card-img-top m-1"
                              alt="..."
                              onClick={() => openLightbox(post.subimg1)}
                            />
                          )}
                          {post.subimg2 && (
                            <img
                              src={post.subimg2}
                              style={{
                                maxWidth: "400px",
                                height: "300px",
                                cursor: "pointer",
                                objectFit: "scale-down",
                              }}
                              className="card-img-top m-1"
                              alt="..."
                              onClick={() => openLightbox(post.subimg2)}
                            />
                          )}
                          {post.submig3 && (
                            <img
                              src={post.submig3}
                              style={{
                                maxWidth: "400px",
                                height: "300px",
                                cursor: "pointer",
                                objectFit: "scale-down",
                              }}
                              className="card-img-top m-1 rounded-3"
                              alt="..."
                              onClick={() => openLightbox(post.submig3)}
                            />
                          )}
                        </div>
                      </div>
                      <h1 className="card-title text-capitalize">
                        {post.title}{" "}
                        {post.promoted === "true" && (
                          <img
                            src={"../../img/Goldbadge.png"}
                            style={{
                              width: "17px",
                              height: "17px",
                              position: "relative",
                              objectFit: "scale-down",
                              marginBottom: "1.5px",
                            }}
                            className="card-img-top"
                            alt="..."
                          />
                        )}{" "}
                      </h1>
                      <p className="card-text text-capitalize">{post.desc}</p>
                      <p className="price">{formattedPrice}</p>
                      <div className="qr-code text-center mt-4">
                        <img
                          src={post.qr}
                          alt=""
                          style={{ maxWidth: "200px" }}
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          PRODUCT QR CODE
                        </p>
                      </div>
                      <hr />
                      <h6>Store Information;</h6>
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href={`/profile/${post.storename}/`}
                      >
                        <p className="text-capitalize">
                          <strong>Store Name:</strong> {post.storetitle}{" "}
                          {post.verified === "true" && (
                            <img
                              src={"../../img/verified.png"}
                              style={{
                                width: "12px",
                                height: "12px",
                                position: "relative",
                                objectFit: "scale-down",
                                marginBottom: "1.5px",
                              }}
                              className="card-img-top"
                              alt="..."
                            />
                          )}
                        </p>
                        <p className="text-capitalize">
                          <strong>Location:</strong> {post.location}
                        </p>
                      </a>
                      <p className="text-capitalize">
                        <strong>Phone:</strong> {post.phone}
                      </p>
                    </div>
                    {currentUser && currentUser.storename === post.storename ? (
                      <>
                        {post.promoted !== "true" && (
                          <Link
                            to={`/promote/${post.id}`}
                            state={post}
                            type="button"
                            className="btn btn-success btn-sm mb-1"
                          >
                            Promote
                          </Link>
                        )}
                        {post.coins >= ediCoin ? (
                          <Link
                            type="button"
                            className="btn btn-primary btn-sm mb-1"
                            to={`/create?edit=2`}
                            state={post}
                          >
                            Edit for {ediCoin} coins
                          </Link>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm mb-1"
                            onClick={() => handleNoCoins(ediCoin, post.coins)}
                          >
                            Edit for {ediCoin} coins
                          </button>
                        )}

                        {post.coins >= delCoin ? (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={handleDelete}
                          >
                            Delete for {delCoin} coins
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleNoCoins(delCoin, post.coins)}
                          >
                            Delete for {delCoin} coins
                          </button>
                        )}
                      </>
                    ) : (
                      <a
                        href={` https://api.whatsapp.com/send?phone=${post.phone.trimEnd()}&text=%20Hi,%20I%20would%20like%20to%20enquire%20about%20this%20product;%0AName:%20*${post.title.trimEnd()}*%0APrice:%20*₦${post.price.trimEnd()}*%0Ahttps://salfad.com/item/${
                          post.id
                        }`}
                        className="btn btn-success"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                        </svg>
                      </a>
                    )}

                    <p className="text-center mt-5">
                      <p>
                        <b>Safety Tips</b>
                      </p>
                      <p>• Avoid paying in advance, even for delivery</p>
                      <p>• Meet with the seller at a safe public place</p>
                      <p>
                        • Inspect the item and ensure it's exactly what you want
                      </p>
                      <p>
                        • Make sure that the packed item is the one you've
                        inspected
                      </p>
                      <p>• Only pay if you're satisfied</p>
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        })
      )}
      {lightboxOpen && (
        <div
          className="lightbox"
          onClick={closeLightbox}
          style={lightboxStyles.overlay}
        >
          <div style={lightboxStyles.content}>
            <img
              src={lightboxImage}
              alt="lightbox"
              style={{
                width: "90vw",
                height: "90vh",
                objectFit: "scale-down",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const lightboxStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  content: {
    width: "90vw",
    height: "90vh",
    marginTop: "25px",
  },
};

export default Single;
