import React, { useState } from "react";
import { makeRequest } from "../../axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import Post from "../../components/post/Post";
import Loading from "../../components/loading/Loading";
import NoItems from "../../components/NoItems/NoItems";
import { useInView } from "react-intersection-observer";
import Load from "../../components/Load";
import { useLocation } from "react-router-dom";
import ShareButton from "../../components/share/Share";

const Promoted = () => {
  const googlesearch = useLocation().search.split("?")[1];

  const [search, setSearch] = useState(
    decodeURIComponent(decodeURIComponent(googlesearch || ""))
  );
  const keys = ["storename", "title", "location", "storetitle", "cat", "desc"];
  const encodedSearch = encodeURIComponent(encodeURIComponent(search));

  const content = {
    title: "Salfad Marketplace",
    text: "Check out amazing products on salfad.com!",
    url: `https://www.salfad.com/exclusive/?${encodedSearch}`,
  };
  const fetchTopProducts = ({ pageParam = 0 }) =>
    makeRequest
      .get(`/posts/top?limit=5&offset=${pageParam}`)
      .then((res) => res.data);

  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["top"],
    queryFn: fetchTopProducts,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length > 0 ? pages.length * 5 : undefined;
    },
  });

  const allPosts = data ? data.pages.flat() : [];

  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  return (
    <div className="posts mb-5">
      <p
        className="row sticky-top top-0 p-2 shadow-sm"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "white",
        }}
      >
        <input
          className="form-control m-2 col-md-3"
          type="search"
          placeholder="Search products, stores and categories"
          aria-label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search.length > 0 && (
          <ShareButton
            title={content.title}
            text={content.text}
            url={content.url}
          />
        )}
      </p>
      <p className="text-center " style={{ width: "100%" }}>
        EXCLUSIVE PRODUCTS
      </p>
      {isError ? (
        "Something went wrong"
      ) : isLoading && !allPosts.length ? (
        <Loading />
      ) : !allPosts.length ? (
        <NoItems text={"No Items Yet"} />
      ) : (
        <>
          {allPosts
            .filter((post) =>
              keys.some((key) =>
                post[key]?.toLowerCase().includes(search.toLowerCase())
              )
            )
            .map((post) => (
              <Post post={post} key={post.id} />
            ))}
          <div
            ref={ref}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "100%",
            }}
          >
            {isFetchingNextPage && (
              <div
                style={{
                  position: "fixed",
                  bottom: "0px",
                  marginBottom: "61px",
                }}
              >
                <Load />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Promoted;
